

import { useLoggedGeladaLazyQuery } from '@netfront/gelada-identity-library';

import { EXPORT_LOGINS } from './useGetLoginsReport.graphql';
import {
  IGetLoginsReportQueryGraphQLResponse,
  IGetLoginsReportQueryVariables,
  IHandleGetLoginsReportParams,
  IUseGetLoginsReport,
  IUseGetLoginsReportOptions,
} from './useGetLoginsReport.interfaces';

const useGetLoginsReport = (options?: IUseGetLoginsReportOptions): IUseGetLoginsReport => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetLoginsReportOptions);

  const [executeGetLoginsReport, { loading: isLoading }] = useLoggedGeladaLazyQuery<
    IGetLoginsReportQueryGraphQLResponse,
    IGetLoginsReportQueryVariables
  >({
    options: {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: ({ report: { exportLogins } }) => {
        if (!onCompleted) {
          return;
        }

        onCompleted({
          report: exportLogins,
        });
      },
      onError,
    },
    query: query ?? EXPORT_LOGINS,
    token,
  });


  const handleGetLoginsReport = async ({
    dateFrom,
    dateTo,
    fileType,
    ianaTimeZone,
    projectId,
    includeImpersonation,
  }: IHandleGetLoginsReportParams) => {
    await executeGetLoginsReport({
      variables: {
        dateFrom,
        dateTo,
        fileType,
        ianaTimeZone,
        projectId,
        includeImpersonation,
      },
    });
  };

  return {
    handleGetLoginsReport,
    isLoading,
  };
};

export { useGetLoginsReport };

import { gql } from '@apollo/client';

const UPDATE_COMMUNITY_STATUS_MUTATION = gql`
  mutation UpdateCommunityStatus(
  $communityId: Int!,
  $userId: Int!,
  $status: EConnectionStatus!,
  ) {
    communityConnection {
      updateCommunityStatus(
        communityId: $communityId,
        userId: $userId,
        status: $status,
      )
    }
  }
`;

export { UPDATE_COMMUNITY_STATUS_MUTATION };

import { gql } from '@apollo/client';

const CREATE_DROPDOWN_LIST_CONFIGURATION = gql`
  mutation createDropDownQuesConfigRequest(
    $projectId: String!
    $description: String!
    $questionResponseSetId: Int!
    $canModify: Boolean!
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { projectId: $projectId, description: $description }
        questionConfigurationDropDownList: {
          canModify: $canModify
          questionResponseSetId: $questionResponseSetId
        }
      ) {
        id
        ... on DropDownList {
          id
          responseSet {
            id
            availableResponses {
              id
              label
              questionResponseSetId
              value
            }
          }
        }
      }
    }
  }
`;

export { CREATE_DROPDOWN_LIST_CONFIGURATION };

import { useEffect, useState } from 'react';

import { AvailableChart, AvailableChartProps, ChartPicker, ILineChartDataset, ILegend, LineChartCard, IDoughnutFeed, DoughnutChartCard, BarChartCard, IBarChartDataset } from '@netfront/ui-library';
import { IGetStudentRegistrationNoTrialOnCompletedResponse, IGetTeacherRegistrationNoTrialOnCompletedResponse, useGetStudentRegistrationNoTrial, useGetTeacherRegistrationNoTrial } from 'hooks';
import { DailyCount } from 'interfaces';

import { InsightsChartsConstants } from '../Insights.constants';

import { UserRegistrationProps } from './NoTrialUserRegistrationChart.interfaces';

const NoTrialUserRegistrationChart = ({ projectId, end, start, isFullWidth = false }: UserRegistrationProps) => {
  const LABEL = 'No trial user registration';
  const { colors: [primaryColor, secondaryColor], borderWidth, barThicknessSmall: barThickness } = InsightsChartsConstants;

  const [activeChart, setActiveChart] = useState<AvailableChart>('Line');
  const [teacherDailyCount, setTeacherDailyCount] = useState<DailyCount[]>([]);
  const [studentDailyCount, setStudentDailyCount] = useState<DailyCount[]>([]);
  const onActiveChartChange = (value: AvailableChart) => {
    setActiveChart(value);
  };

  const onGetTeacherRegistrationCompleted = (data: IGetTeacherRegistrationNoTrialOnCompletedResponse) => {
    const { userRegistration } = data;
    setTeacherDailyCount(userRegistration);
  };

  const { handleGetTeacherRegistrationNoTrial } = useGetTeacherRegistrationNoTrial({
    onCompleted: onGetTeacherRegistrationCompleted,
  });

  const onGetStudentRegistrationCompleted = (data: IGetStudentRegistrationNoTrialOnCompletedResponse) => {
    const { userRegistration } = data;
    setStudentDailyCount(userRegistration);
  };

  const { handleGetStudentRegistrationNoTrial } = useGetStudentRegistrationNoTrial({
    onCompleted: onGetStudentRegistrationCompleted,
  });


  const legends: ILegend[] = [
    {
      color: primaryColor,
      label: 'Teachers',
    },
    {
      color: secondaryColor,
      label: 'Students',
    },
  ];

  const getTotalCount = () => {
    return teacherDailyCount.reduce((acc, dc) => {
      acc += dc.count;
      return acc;
    }, 0)
    + studentDailyCount.reduce((acc, dc) => {
      acc += dc.count;
      return acc;
    }, 0) ;
  }

  const doughnutData: IDoughnutFeed[] = [
    {
      backgroundColor: primaryColor,
      borderColor: primaryColor,
      label: 'Teach',
      value: teacherDailyCount.reduce((acc, dc) => {
        acc += dc.count;
        return acc;
      }, 0),
    } as IDoughnutFeed,
    {
      backgroundColor: secondaryColor,
      borderColor: secondaryColor,
      label: 'Student',
      value: studentDailyCount.reduce((acc, dc) => {
        acc += dc.count;
        return acc;
      }, 0),
    } as IDoughnutFeed,
  ];

  const lineChart: ILineChartDataset[] = [
    {
      backgroundColor: primaryColor,
      borderColor: primaryColor,
      data: teacherDailyCount.map((r) => r.count),
      label: 'Teacher',
      borderWidth,
    },
    {
      backgroundColor: secondaryColor,
      borderColor: secondaryColor,
      data: studentDailyCount.map((r) => r.count),
      label: 'Student',
      borderWidth,
    },
  ];

  const barData: IBarChartDataset[] = [
    {
      backgroundColor: [primaryColor],
      data: teacherDailyCount.map((d) => d.count),
      label: '',
      id: 'id_teacher_key',
      barThickness,
      borderWidth,
    },
    {
      backgroundColor:[secondaryColor],
      data: studentDailyCount.map((d) => d.count),
      label: '',
      id: 'id_student_key',
      barThickness,
      borderWidth,
    },
  ];

  const availableCharts: AvailableChartProps[] = [
    {
      value: 'Line',
      component: <LineChartCard datasets={lineChart} displayValue={String(getTotalCount())} label={LABEL} labels={teacherDailyCount.map((r) => new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }))} legends={legends} />,
    },
    {
      value: 'Bar',
      component: (
        <BarChartCard
          datasets={barData}
          displayedValue={String(getTotalCount())}
          label={LABEL}
          labels={teacherDailyCount.map((r) => new Date(r.date).toLocaleDateString('en-AU', { day: '2-digit', month: '2-digit' }))}
          legends={legends}
        />
      ),
    },
    { value: 'Doughnut', component: <DoughnutChartCard feed={doughnutData} label={LABEL} /> }
  ];


  useEffect(() => {
    if (!projectId) return;
    void handleGetTeacherRegistrationNoTrial({
      end,
      projectId,
      start,
    });

    void handleGetStudentRegistrationNoTrial({
      end,
      projectId,
      start,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start, end, projectId]);


  return (
    <ChartPicker
      availableCharts={availableCharts}
      defaultSelection={activeChart}
      id="id_no_trial_user_registration_chart_picker"
      isFullWidth={isFullWidth}
      onChange={onActiveChartChange}
    />
  );
};

export { NoTrialUserRegistrationChart };

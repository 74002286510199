import React, { ChangeEvent, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useReactHookFormValidationHelpers } from '@netfront/common-library';
import { ButtonIconOnly, ControlledForm, FlexContainer, FormFieldProps, Input, Message, PlusIcon, SidebarButtons, Spacing, Spinner, ToggleSwitch, useControlledForm } from '@netfront/ui-library';
import { ContentBuilderTabWrapper, IResponseGroupItem, ResponseSetItem } from 'components';
import { useCreateResponseSet, useToast } from 'hooks';
import { Control, Controller } from 'react-hook-form';
import { generateRandomId, getIncrementValueByKey } from 'utils';
import * as yup from 'yup';


import { CreateResponseSetProps } from './CreateResponseSet.interfaces';

import { responseTypeToUnderScoredConst } from '../../../../../constants';


const CreateResponseSet = ({ onCancel, onSave, projectId, responseType, additionalBreadcrumbs }: CreateResponseSetProps) => {
  const { handleToastError, handleToastSuccess } = useToast();
  const [defaultValues, setDefaultValues] = useState<FormFieldProps>();

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();
  const { control, handleSubmit, reset, setValue } = useControlledForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape({
        title: yup.string().label('Title').required(),
        responseGroup: yup.array().required().min(1, 'Please provide at least one option'),
      }),
    ),
  });

  const [title, setTitle] = useState('');
  const [isAllowImageUpload, setIsAllowImageUpload] = useState<boolean>(false);

  const { handleCreateResponseSet, isLoading = false } = useCreateResponseSet({
    onCompleted: ({ config }) => {
      reset();
      onSave({
        ...config,
        title,
      });
      handleToastSuccess({
        message: `Response set successfully created`,
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });


  const handleSave = (item: FormFieldProps) => {
    const { title: responseGroupTitle, responseGroup = []} = item;

    const formattedResponseGroup = (responseGroup as IResponseGroupItem[]).map(({ label, value, assetId }) => ({
      label,
      scoreValue: Number.isNaN(value) ? 0 : Number(value),
      value,
      assetId: isAllowImageUpload && assetId ? assetId : undefined,
    }));

    void handleCreateResponseSet({
      title: responseGroupTitle,
      projectId,
      responsesRadio: responseType === responseTypeToUnderScoredConst.Radio ? formattedResponseGroup : undefined,
      responsesCheckbox: responseType === responseTypeToUnderScoredConst.Checkbox ? formattedResponseGroup : undefined,
      responsesDropDownList: responseType === responseTypeToUnderScoredConst.DropDownList ? formattedResponseGroup : undefined,
    });
  };

  const upsertResponseGroupItem = ({ item, responseGroup } : {item: IResponseGroupItem, responseGroup: IResponseGroupItem[]}) => {

    const editedResponseGroup = responseGroup.map((currentItem) => {
      if (currentItem.id === item.id) {
        return {
          ...currentItem,
          ...item,
          isEditableOnLoad: false,
        };
      }
      return currentItem;
    });
    setValue('responseGroup', editedResponseGroup);
  };

  const removeResponseGroupItem = (id: string, responseGroup: IResponseGroupItem[]) => {
    const editedResponseGroup = responseGroup.filter(({id: currentItemId}) => currentItemId !== id);
    setValue('responseGroup', editedResponseGroup);
  };

  const handleAddNewResponse = (responseGroup: IResponseGroupItem[]) => {
    const res = [
      ...responseGroup,
      {
        label: '',
        value: responseGroup.length ? getIncrementValueByKey<IResponseGroupItem>(responseGroup, 'value').toString() : '0',
        scoreValue: 0,
        id: generateRandomId(),
        isEditableOnLoad: true,
      },
    ];

    setValue('responseGroup', res);
  };

  useEffect(() => {
    setDefaultValues({
      title: '',
      responseGroup: [
        {
          id: generateRandomId(),
          label: '',
          value: '0',
          scoreValue: 0,
          isEditableOnLoad: true,
        }
      ] as IResponseGroupItem[],
    })
  }, [responseType]);


  return (
    <ContentBuilderTabWrapper additionalBreadcrumbs={additionalBreadcrumbs}>
      <ControlledForm
        callBack={(item: FormFieldProps) => {
          handleSave(item);
        }}
        handleSubmit={handleSubmit}
        isStopPropagation
      >
        <Spinner isLoading={isLoading} />
        {defaultValues && (
          <>
            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="title"
                render={({ field, fieldState }) => (
                  <Input
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id="id_text_snippet_title"
                    labelText="Title"
                    type="text"
                    isLabelSideBySide
                    isRequired
                    {...field}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const { target: { value }} = event;
                      field.onChange(event);
                      setTitle(value);
                    }}
                  />
                )}
              />
            </Spacing>
            {[responseTypeToUnderScoredConst.Radio, responseTypeToUnderScoredConst.Checkbox].includes(responseType) && (
              <Spacing>
                <Controller
                  control={control as Control<FormFieldProps>}
                  name="isAllowImageUpload"
                  render={({ field }) => (
                    <ToggleSwitch
                      additionalClassNames={`c-sidebar-toggle c-asset-list-toggle`}
                      id="id_allow_image_upload"
                      isChecked={field.value}
                      labelText="Add images"
                      isInline
                      isLabelSideBySide
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { target: { checked: isChecked }} = event;
                        field.onChange(event);
                        setIsAllowImageUpload(isChecked);
                      }}
                    />
                  )}
                />
              </Spacing>
            )}

            <Spacing>
              <Controller
                control={control as Control<FormFieldProps>}
                name="responseGroup"
                render={({ field, fieldState }) => {
                  const errorMessage = getFormFieldErrorMessage(fieldState);
                  return (
                    <>
                    {field.value && (
                      <>
                        {(field.value as IResponseGroupItem[]).map((item, index) => (
                          <ResponseSetItem
                            key={item.id}
                            handleDeleteResponseItem={(id: string) => removeResponseGroupItem(id, field.value as IResponseGroupItem[])}
                            handleUpsertResponseItem={(currentItem: IResponseGroupItem) => upsertResponseGroupItem({item: currentItem, responseGroup: field.value as IResponseGroupItem[]})}
                            hasUploadImages={isAllowImageUpload}
                            index={item.id}
                            isLabelHidden={index !== 0}
                            item={item}
                            projectId={projectId}
                          />
                        ))}
                      </>
                    )}

                      {Boolean(errorMessage) && <Message id={`id_response_group_error`} text={errorMessage} type="ERROR" />}
                      <FlexContainer justifyContent="end">
                        <ButtonIconOnly icon={PlusIcon} text="Add Response" onClick={() => handleAddNewResponse(field.value as IResponseGroupItem[])} />
                      </FlexContainer>
                    </>
                  );
                }}
              />
            </Spacing>

            <SidebarButtons
              onCancel={onCancel}
              onSaveButtonType="submit"
            />
          </>
        )}


      </ControlledForm>
    </ContentBuilderTabWrapper>
  );
};

export { CreateResponseSet };

import { gql } from '@apollo/client';

const EXPORT_LOGINS = gql`
  query ExportLogins($ianaTimeZone: String!, $projectId: String!, $dateFrom: DateTime!, $dateTo: DateTime!, $fileType: FileType, $includeImpersonation: Boolean) {
    report {
      exportLogins(ianaTimeZone: $ianaTimeZone, projectId: $projectId, dateFrom: $dateFrom, dateTo: $dateTo, fileType: $fileType, includeImpersonation: $includeImpersonation) {
        contentType
        s3Key
        presignedUrl
      }
    }
  }
`;

export { EXPORT_LOGINS };

import { useContext, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { Button, ExcelIcon, FlexContainer, ToggleSwitch } from '@netfront/ui-library';
import axios, { AxiosResponse } from 'axios';
import { ReportTable } from 'components';
import { CachingEntitiesContext, ProjectSettingsContext } from 'context';
import { addMonths } from 'date-fns';
import { useGetLoginsReport, useToast } from 'hooks';
import { useRouter } from 'next/router';
import Papa from 'papaparse';

import { LOGINS_COLUMNS } from './LoginsPage.constants';
import { getLoginsTableData } from './LoginsPage.helpers';
import { LoginsCsvRowData, ILoginsItem, ILoginsTableData } from './LoginsPage.interfaces';



const LoginsPage = () => {
  const { project } = useContext(CachingEntitiesContext);
  const { projectSettings } = useContext(ProjectSettingsContext);
  const { query: { projectId: queryProjectId } } = useRouter();
  const { handleToastError, handleToastCustomError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [projectName, setProjectName] = useState<string>();
  const [loginsItems, setLoginsItems] = useState<ILoginsItem[]>();
  const [allLoginsItems, setAllLoginsItems] = useState<ILoginsItem[]>([]);
  const [loginsTableData, setLoginsTableData] = useState<ILoginsTableData[]>([]);
  const [dateRange, setDateRange] = useState<Date[]>([addMonths(new Date(), -1), new Date()]);
  const [csvUrl, setCsvUrl] = useState<string>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [lastItemIndex, setLastItemIndex] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isLoadMoreDisabled, setIsLoadMoreDisabled] = useState<boolean>(false);
  const [isGenerate, setIsGenerate] = useState<boolean>(false);
  const [isDeIdentified, setIsDeIdentified] = useState<boolean>(false);
  const [isIncludeImpersonatedLogins, setIsIncludeImpersonatedLogins] = useState<boolean>(false);

  const {
    handleGetLoginsReport,
    isLoading: isGetLoginsLoading = false,
  } = useGetLoginsReport({
    fetchPolicy: 'no-cache',
    onCompleted: ({ report: { presignedUrl } }) => {
      if (!presignedUrl) return;

      if (isGenerate) {
        setIsGenerate(false);
        setCsvUrl(presignedUrl);
        axios
          .get(presignedUrl)
          .then((res: AxiosResponse) => {

            const { data } = Papa.parse<LoginsCsvRowData>(String(res.data), {
              header: true,
            skipEmptyLines: true,
            });

            const formattedItems = data.map((row: LoginsCsvRowData) => ({
              id: Number(row['User id']),
              firstName: isDeIdentified ? 'De-identifed' : String(row['First name']),
              lastName: isDeIdentified ? 'De-identifed' : String(row['Last name']),
              email: isDeIdentified ? 'De-identifed' : String(row['Email']),
              groupName: String(row['Group name']),
              loginDate: String(row['Login date']),
              accessType: String(row['Access type'])
            }));

            
            setTotalCount(data.length);
            setAllLoginsItems(formattedItems);
            const paginatedItems = formattedItems.slice(0, pageSize);
            setLoginsItems(paginatedItems);
            setLastItemIndex(pageSize);
            
          })
          .catch((error) => {
            handleToastCustomError({
              message: 'whoops! something went wrong',
            });
            // eslint-disable-next-line no-console
            console.log({ error });
          });
      } else {
        window.open(presignedUrl, '_blank');
      }
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const generateReport = () => {
    if (!projectId) {
      return;
    }

    if (!dateRange[1] || !dateRange[0]) return;

    setIsGenerate(true);

    void handleGetLoginsReport({
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: 'CSV',
      projectId,
      includeImpersonation: isIncludeImpersonatedLogins,
    });
  };

  const handleDownload = (type: "CSV" | "EXCEL") => {
    if (!projectId) {
      return;
    }

    if (!dateRange[1] || !dateRange[0]) return;

    setIsGenerate(false);

    void handleGetLoginsReport({
      dateFrom: dateRange[0],
      dateTo: dateRange[1],
      ianaTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      fileType: type,
      projectId,
      includeImpersonation: isIncludeImpersonatedLogins,
    });
  };

  const handleChangePageSize = (selectedPageSize: number) => {
    setPageSize(selectedPageSize);
    setLastItemIndex(selectedPageSize);
    setIsLoadMoreDisabled(totalCount <= selectedPageSize);
    setLoginsItems(allLoginsItems.slice(0, selectedPageSize));
  };
  
  const handlePaginate = () => {
    return new Promise<void>((resolve) => {
      const updateIndex = lastItemIndex + pageSize;
      setLastItemIndex(updateIndex);
      setIsLoadMoreDisabled(totalCount <= updateIndex);
      setLoginsItems(allLoginsItems.slice(0, updateIndex));
      resolve();
    });
  };

  useEffect(() => {
    if (!loginsItems) {
      return;
    }

    setLoginsTableData(
      getLoginsTableData({
        items: loginsItems,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginsItems]);

  useEffect(() => {
    if (!project) return;

    setProjectName(project.name);
  }, [project]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    if (!projectSettings) return;

    setIsDeIdentified(projectSettings['DE_IDENTIFY_REPORTS'] as boolean)
  }, [projectSettings]);

  const isLoading = isGetLoginsLoading;

  return (
    <ReportTable<ILoginsTableData>
      activePath={['reporting', 'reporting-users', 'export-logins']}
      additionalBreadcrumbItems={[{
        key: '2',
        content: 'Logins',
      }]}
      additionalDownloadOptions={
        csvUrl ? (
        <Button
          additionalClassNames="c-report-download-button"
          icon={ExcelIcon}
          size="xs"
          text="Download as excel"
          isIconOnly
          onClick={() => handleDownload('EXCEL')}
        />
      ): undefined}
      additionalFilters={
        <FlexContainer justifyContent="flex-end">
        <ToggleSwitch
          additionalClassNames={`c-report-toggle`}
          id="id_include_impersonated_logins"
          isChecked={isIncludeImpersonatedLogins}
          labelText="Include impersonated logins?"
          isInline
          onChange={() => setIsIncludeImpersonatedLogins(!isIncludeImpersonatedLogins)}
        />
        </FlexContainer>
      }
      columns={LOGINS_COLUMNS}
      csvUrl={csvUrl}
      data={loginsTableData}
      dateRange={dateRange}
      description={`Login reports for ${String(projectName)}`}
      handleOnPageSizeChange={handleChangePageSize}
      handleOnPaginate={handlePaginate}
      informationBoxMessage={'This page exports logins based on the selected filters'}
      isGenerateButtonDisabled={!dateRange[0] || !dateRange[1]}
      isLoading={isLoading}
      isLoadMoreDisabled={isLoadMoreDisabled}
      logoUrl={project?.logo?.presignedUrl}
      pageSize={pageSize}
      pageTitle={String(projectName)}
      title={`${String(projectName)} reporting`}
      totalCount={totalCount}
      onDateSelect={(dates: Date[]) => setDateRange(dates)}
      onGenerate={generateReport}
    />
  );
};

export { LoginsPage };

import { IPillVariant } from "@netfront/ui-library";

const userConnectionStatusNameMap: {[key: string]: string } = {
  ACCEPTED: 'Member',
  ALUMNI: 'Alumni',
  BLOCKED: 'Blocked',
  DECLINED: 'Declined',
  REQUESTED: 'Pending',
};

const userConnectionStatusColorMap: {[key: string]: IPillVariant } = {
  ACCEPTED: 'green',
  ALUMNI: 'green',
  BLOCKED: 'red',
  DECLINED: 'red',
  REQUESTED: 'blue',
};

export { userConnectionStatusNameMap, userConnectionStatusColorMap };
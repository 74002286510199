/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useContext, ReactNode } from 'react';

import { ApolloError } from '@apollo/client';
import { IContentGroup } from '@netfront/ekardo-content-library';
import { useBackofficeSiteUrls, useDomain } from '@netfront/gelada-identity-library';
import {
  Button,
  CardListPageItems,
  FlexContainer,
  Pill,
  Spinner,
} from '@netfront/ui-library';
import { CardListTemplatePage , ContentGroupListSidebarView , StatusPill } from 'components';
import { CachingEntitiesContext, DashboardContext, ProjectSettingsContext } from 'context';
import capitalize from 'lodash/capitalize';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { ContentGroupListProps } from './ContentGroupList.interfaces';


import { useGetContentGroups, useToast } from '../../../../../hooks';

const ContentGroupList = ({ contentGroupType }: ContentGroupListProps) => {
  const { project } = useContext(CachingEntitiesContext);
  const { dashboardLink } = useContext(DashboardContext);
  const { projectSettings } = useContext(ProjectSettingsContext);
  const { query: { projectId: queryProjectId, isBetaVersion: queryIsBetaVersion }, push, asPath } = useRouter();
  const { isDomainReady } = useDomain();
  const { handleToastError } = useToast();

  const [projectId, setProjectId] = useState<string>('');
  const [isSideBarOpen, setIsSideBarOpen] = useState<boolean>(false);
  const [organisationKey, setOrganisationKey] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<IContentGroup>();
  const [filter, setFilter] = useState<string>();
  const [allItems, setAllItems] = useState<IContentGroup[]>([]);
  const [isBetaVersion, setIsBetaVersion] = useState<boolean>(false);

  const { getDashboardUrl } = useBackofficeSiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_BACK_OFFICE_LOCAL_PORT,
  });
  const [backofficeBaseUrl, setBackofficeBaseUrl] = useState<string>('');

  const handleSwitch = () => {
    push(`${asPath.split('?')[0]}/${!isBetaVersion ? '?isBetaVersion=true': ''}`).catch((error) =>
      handleToastError({
        error,
      }),
    );
  };


  const {
    handleGetContentGroups,
    isLoading: isGetContentGroupsLoading = false
  } = useGetContentGroups({
    fetchPolicy: 'no-cache',
    onCompleted: ({ contentGroups }) => {
      setAllItems(contentGroups.sort((a, b) => a.sort - b.sort));
    },
    onError: (error: ApolloError) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const handleAddNewContentGroup = () =>{
    setSelectedItem(undefined);
    setIsSideBarOpen(true);
  }

  const handleSelectItem = (item: IContentGroup) => {
    setSelectedItem(item);
    setIsSideBarOpen(true);
  }

  const handleSideBarClose = () => {
    setSelectedItem(undefined);
    setIsSideBarOpen(false);
  }

  const handleUpdate = () => {
    handleSideBarClose();
    handleGetContentGroups({
      projectId: String(projectId),
      type: [contentGroupType],
      title: filter

    });
  }


  useEffect(() => {
    if (!projectId) return;
    handleGetContentGroups({
      projectId: String(projectId),
      type: [contentGroupType],
      title: filter

    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, contentGroupType, filter]);

  useEffect(() => {
    if (!(project && isDomainReady)) return;

    setOrganisationKey(project.organisation.key);
    setProjectName(project.name);
    setBackofficeBaseUrl(`${getDashboardUrl()}/${String(project.organisation.key)}/${String(projectId)}/group/${contentGroupType.toLowerCase()}`);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isDomainReady]);

  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId]);

  useEffect(() => {
    setIsBetaVersion( queryIsBetaVersion === 'true' && projectSettings?.['HAS_NEW_BUILDER'] as boolean);
  }, [queryIsBetaVersion, projectSettings]);

  return (
    <>
      <Spinner isLoading={isGetContentGroupsLoading}/>
      <CardListTemplatePage
        activePage="content"
        activeSubPage={`${contentGroupType.toLowerCase()}`}
        additionalClassNames="c-card-list-page-template"
        addNewOnClick={handleAddNewContentGroup}
        breadcrumbItems={[
          {
            key: '0',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}`}><span>Dashboard</span></Link>,
          },
          {
            key: '1',
            content: <Link href={`/dashboard/${String(organisationKey)}/${String(projectId)}/content`}><span>Content</span></Link>,
          },
          {
            key: '2',
            content: capitalize(contentGroupType.toLowerCase()),
          },
        ]}
        defaultView={allItems.length >= 10 ? 'list': 'grid'}
        description={`Project dashboard for ${projectName} project`}
        informationBoxMessage={(
          <FlexContainer alignItems="center" justifyContent="space-between">
            <FlexContainer alignItems="center" gap="small">
              {isBetaVersion && (
                <Pill value="BETA" variant="green"/>
              )}
              <span>Manage <strong>{projectName}</strong> project</span>
            </FlexContainer>
            {projectSettings?.['HAS_NEW_BUILDER'] as boolean && (
              <Button
                size="xs"
                text={`Switch to ${isBetaVersion ? 'Legacy': 'Beta'}`}
                variant="secondary"
                onClick={handleSwitch}
              />
            )}
          </FlexContainer>
        )}
        isLoading={isGetContentGroupsLoading}
        isSideBarOpen={isSideBarOpen}
        lists={[
          {
            isCentered: false,
            items: allItems.map((item) => {
              const { id, title, iconAsset, description = '', status = 'UNPUBLISHED', firstPage, url } = item;
              const { presignedUrl = '' } = iconAsset ?? {};
              const { url: pageUrl = ''} = firstPage ?? {};
              return {
                type: 'nav',
                searchKey: title,
                itemProps: {
                  key: String(id),
                  title,
                  logoUrl: presignedUrl,
                  isLogoFullWidth: Boolean(presignedUrl),
                  linkUrl: isBetaVersion ? `${String(dashboardLink)}/content/builder/${contentGroupType.toLowerCase()}/${url}/${pageUrl}` : `${String(backofficeBaseUrl)}/${id}/content`,
                  wrapperComponent: isBetaVersion ? ({ children }: { children: ReactNode }) => <Link className="c-cover-link" href={`${String(dashboardLink)}/content/builder/${contentGroupType.toLowerCase()}/${url}/${pageUrl}`}>{children} </Link>: undefined,
                  settingsButtonSupportiveText: 'Edit content group',
                  settingsButtonOnClick: () => handleSelectItem(item),
                  tooltipText: description,
                  additionalFooterComponent: <StatusPill isHighlighted={status === 'PUBLISHED'} status={status}/>,
                },
              } as CardListPageItems;
            })
          }
        ]}
        listType={contentGroupType === 'NEWS' ? 'new': contentGroupType.toLowerCase()}
        logoUrl={project?.logo?.presignedUrl}
        pageTitle={`${projectName ? projectName + ' dashboard' : 'Dashboard'}`}
        projectName={projectName}
        onSearch={(value?: string) => setFilter(value)}
      />

      <ContentGroupListSidebarView
        contentGroupType={contentGroupType}
        handleSideBarClose={handleSideBarClose}
        isSideBarOpen={isSideBarOpen}
        projectId={String(projectId)}
        selectedContentGroup={selectedItem}
        onUpdate={handleUpdate}
      />

    </>
  );
};

export { ContentGroupList };

import { gql } from '@apollo/client';

const GET_USER_COMMUNITIES_QUERY = gql`
  query GetUserCommunities(
    $after: String
    $first: Int
    $filter: String
    $userId: Int
    $status: [EConnectionStatus]
    $shouldIncludeBannerImage: Boolean = false
    $shouldIncludeProfileImage: Boolean = false
  ) {
    community {
      userCommunities(after: $after, first: $first, filter: $filter, userId: $userId, status: $status) {
        edges {
          cursor
          node {
            bannerImage @include(if: $shouldIncludeBannerImage) {
              presignedUrl
            }
            id
            description
            title
            privacy
            profileImage @include(if: $shouldIncludeProfileImage) {
              presignedUrl
            }
            status
            userConnection(userId: $userId) {
              role
              id
              userId
              status
            }
          }
        }
        totalCount
      }
    }
  }
`;

export { GET_USER_COMMUNITIES_QUERY };

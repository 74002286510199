import { gql } from '@apollo/client';

const CREATE_CHECKBOX_CONFIGURATION = gql`
  mutation createCheckboxQuesConfigRequest(
    $allowUserGeneratedResponses: Boolean
    $description: String!
    $maxCheckedResponse: Int!
    $questionResponseSetId: Int!
    $projectId: String!
    $style: EQuestionSelectionStyle
  ) {
    questionConfiguration {
      createQuestionConfiguration(
        baseConfiguration: { description: $description, projectId: $projectId }
        questionConfigurationCheckbox: {
          allowUserGeneratedResponses: $allowUserGeneratedResponses
          maxCheckedResponse: $maxCheckedResponse
          questionResponseSetId: $questionResponseSetId
          style: $style
        }
      ) {
        ... on Checkbox {
          id
          description
          maxCheckedResponse
          responseSet {
            id
            availableResponses {
              id
              label
              questionResponseSetId
              value
            }
          }
        }
      }
    }
  }
`;

export { CREATE_CHECKBOX_CONFIGURATION };

import { useBonoboMutation } from '../useBonoboMutation';

import { UPDATE_COMMUNITY_STATUS_MUTATION } from './useUpdateCommunityStatus.graphql';
import {
  IUpdateCommunityStatusMutationGraphQLResponse,
  IUpdateCommunityStatusMutationVariables,
  IHandleUpdateCommunityStatusParams,
  IUseUpdateCommunityStatusOptions,
  IUseUpdateCommunityStatus,
} from './useUpdateCommunityStatus.interfaces';

const useUpdateCommunityStatus = (options?: IUseUpdateCommunityStatusOptions): IUseUpdateCommunityStatus => {
  const { mutation, onCompleted, onError, token, projectId: bonoboProjectId } = options ?? ({} as IUseUpdateCommunityStatusOptions);

  const [executeUpdateCommunityStatus, { loading: isLoading }] = useBonoboMutation<
    IUpdateCommunityStatusMutationGraphQLResponse,
    IUpdateCommunityStatusMutationVariables
  >({
    mutation: mutation ?? UPDATE_COMMUNITY_STATUS_MUTATION,
    options: {
      context: {
        headers: {
          project_id: bonoboProjectId,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          communityConnection: { updateCommunityStatus: isCompleted },
        } = data;

        onCompleted({ isCompleted });
      },
      onError,
    },
    token,
  });

  const handleUpdateCommunityStatus = async ({ communityId, userId, status }: IHandleUpdateCommunityStatusParams) => {
    await executeUpdateCommunityStatus({
      variables: {
        communityId,
        userId,
        status
      },
    });
  };

  return {
    handleUpdateCommunityStatus,
    isLoading,
  };
};

export { useUpdateCommunityStatus };
